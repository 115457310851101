const IndexPage = () => {
  // Redirect index page
  const url = 'https://www.letsgo.ch/ch/'
  if (typeof window !== `undefined` && typeof url !== `undefined`) {
    window.location.href = url
  }

  return null
}

export default IndexPage
